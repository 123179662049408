import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
  IconButton,
  VStack,
  Image,
  Grid,
  Container,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Tooltip,
  Skeleton,
  useToast,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from "framer-motion";
import {
  SunIcon,
  MoonIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  CalendarIcon,
  InfoIcon,
} from "@chakra-ui/icons";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import theme from "./theme";
import EnhancedMarquee from "./EnhancedMarquee";
import PhotoGallery from "./PhotoGallery";
import ModernLiveMusicSection from "./ModernLiveMusicSection";
import WorkWithUs from "./WorkWithUs";
import Header from "./Header";
import SponsorSection from "./components/SponsorSection";
import UpcomingEvents from "./UpcomingEvents";
import FeaturedLocations from "./FeaturedLocations";
import EnhancedStageSchedules from "./EnhancedStageSchedules";
import AboutPage from "./AboutPage";
import EntertainmentPage from "./EntertainmentPage";
import VerticalVideo from "./components/VerticalVideo";
import GiveawayPage from "./GiveawayPage"; // Import the new GiveawayPage component

// Simulated API call
const fetchArtLocations = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          id: 1,
          name: "Pastel Dreams Gallery",
          description:
            "Featuring a collection of motivational and inspirational artworks.",
          address: "123 Inspiration Ave, Culver City",
          image:
            "https://ik.imagekit.io/mbhxghyf1m2/Yellow%20Pink%20Illustrative%20Character%20Trendy%20Typography%20Motivational%20Quote%20Poster_nP4KWkOBDy.png",
        },
        {
          id: 2,
          name: "Retro Cinema Museum",
          description:
            "Explore the golden age of cinema through vintage movie posters and memorabilia.",
          address: "456 Hollywood Blvd, Culver City",
          image:
            "https://ik.imagekit.io/mbhxghyf1m2/Beige%20Retro%20Style%20Movie%20Poster_-_nwdd4Xoh.png",
        },
        {
          id: 3,
          name: "Timeless Art Space",
          description:
            "A hub for contemporary artists inspired by retro and vintage aesthetics.",
          address: "789 Nostalgia Lane, Culver City",
          image:
            "https://ik.imagekit.io/mbhxghyf1m2/Brown%20and%20Cream%20Retro%20Coming%20Soon%20Poster_twUnbm3PY.png",
        },
        {
          id: 4,
          name: "Culver City Arts District",
          description:
            "The heart of Culver City's vibrant art scene, featuring rotating exhibitions.",
          address: "101 Creative Ave, Culver City",
          image:
            "https://ik.imagekit.io/mbhxghyf1m2/Pink%20and%20Purple%20Pastel%20Aesthetic%20Motivational%20Quotes%20Poster_i1jWh_H_4.png",
        },
      ]);
    }, 1500);
  });
};

// Animated text component
const AnimatedText = ({ text }) => {
  const letters = Array.from(text);

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: -20,
      y: 10,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      style={{ overflow: "hidden", display: "flex", fontSize: "2rem" }}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      {letters.map((letter, index) => (
        <motion.span variants={child} key={index}>
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

// Floating element animation
const floatingAnimation = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, -10px) rotate(5deg); }
  50% { transform: translate(0, -20px) rotate(0eg); }
  75% { transform: translate(-10px, -10px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const FloatingElement = ({ children }) => (
  <Box
    animation={`${floatingAnimation} 6s ease-in-out infinite`}
    display="inline-block"
  >
    {children}
  </Box>
);

// Cursor spotlight effect
const CursorSpotlight = () => {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 25, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 75);
      cursorY.set(e.clientY - 75);
    };
    window.addEventListener("mousemove", moveCursor);
    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <motion.div
      className="cursor"
      style={{
        background:
          "radial-gradient(circle at center, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 80%)",
        height: 150,
        width: 150,
        borderRadius: "50%",
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: 9999,
        x: cursorXSpring,
        y: cursorYSpring,
      }}
    />
  );
};

// LocationCard component
const LocationCard = ({ location }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          borderRadius="xl"
          overflow="hidden"
          boxShadow="xl"
          position="relative"
          height="400px"
          transition="all 0.3s"
          transform={isHovered ? "scale(1.05)" : "scale(1)"}
        >
          <Image
            src={location.image}
            alt={location.name}
            objectFit="cover"
            w="100%"
            h="100%"
            transition="transform 0.3s ease-in-out"
            transform={isHovered ? "scale(1.1)" : "scale(1)"}
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            bg="rgba(0,0,0,0.7)"
            color="white"
            p="6"
            transform={isHovered ? "translateY(0)" : "translateY(100%)"}
            transition="transform 0.3s ease-in-out"
          >
            <Heading as="h3" size="md" mb={2}>
              {location.name}
            </Heading>
            <Text fontSize="sm" mb={2}>
              {location.description}
            </Text>
            <Text fontSize="xs" mb={4}>
              <InfoIcon mr={2} />
              {location.address}
            </Text>
            <Button
              rightIcon={<ExternalLinkIcon />}
              colorScheme="brand"
              size="sm"
              width="full"
              _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
            >
              Learn More
            </Button>
          </Box>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

// Hero Section with Video Background
const HeroSection = ({ handleStartJourney, buttonSize }) => {
  const overlayBg = useColorModeValue("rgba(0,0,0,0.4)", "rgba(0,0,0,0.6)");
  const videoRef = React.useRef(null);
  const [loopCount, setLoopCount] = React.useState(0);

  React.useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', () => {
        setLoopCount(prevCount => prevCount + 1);
        if (loopCount < 1) {
          videoElement.play();
        }
      });
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', () => {});
      }
    };
  }, [loopCount]);

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      rotate: -10,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
        duration: 0.8,
      },
    },
  };

  return (
    <Box
      as="section"
      position="relative"
      height="100vh"
      width="100%"
      overflow="hidden"
      bg="gray.100"
    >
      {/* NOTE: Video Background temporarily commented out - DO NOT DELETE */}
      {/* <Box
        as="video"
        ref={videoRef}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        src="https://z-assets.s3.us-west-1.amazonaws.com/Art%2BWalk%2B2019.mp4"
        autoPlay
        muted
        playsInline
      /> */}

      {/* New Image Background */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundImage="url('https://assetsjcoholan.s3.us-west-1.amazonaws.com/art_walk_site/DSC07900_SuDtct-nL.jpg')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      />

      {/* Overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg={overlayBg}
      />

      {/* Content */}
      <Container
        maxW="container.xl"
        height="100%"
        position="relative"
        zIndex="1"
      >
        <VStack
          spacing={8}
          align="center"
          justify="center"
          height="100%"
          color="white"
        >
          <motion.div
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          >
            <Box
              width="800px"
              maxWidth="95%"
              height="300px"
              margin="0 auto"
              mb={8}
              backgroundImage="url('https://assetsjcoholan.s3.us-west-1.amazonaws.com/art_walk_site/Art_Walk_aVWZ250-3.png')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <Text
              fontSize="xl"
              maxW="2xl"
              textAlign="center"
              textShadow="none"
              color="white"
            >
              This FREE Festival will feature distinguished artists, live music,
              eclectic food trucks, beer gardens, ankid's activities!
            </Text>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.7 }}
          >
            <Button
              size={buttonSize}
              colorScheme="brand"
              rightIcon={<ExternalLinkIcon />}
              _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
              transition="all 0.2s"
              onClick={handleStartJourney}
            >
              Start Your Journey
            </Button>
          </motion.div>
        </VStack>
      </Container>
    </Box>
  );
};

// Main App component
const App = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [artLocations, setArtLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const navigate = useNavigate();

  // Define events data
  const events = [
    {
      id: 1,
      name: "Art Festival",
      date: "October 5th, 2024",
      image: "https://ik.imagekit.io/mbhxghyf1m2/PA060142_DY86mPz7Or.jpg",
    },
    {
      id: 2,
      name: "",
      date: "10AM - 6PM",
      image: "https://ik.imagekit.io/mbhxghyf1m2/PA060170_o5OuAjp_5.jpg",
    },
    {
      id: 3,
      name: "Food & Beverage",
      date: "All Day",
      image: "https://ik.imagekit.io/mbhxghyf1m2/PA060058_HrqmXKI04.jpg",
    },
  ];

  // Define artist data
  const artist = {
    name: "All Day",
    bio: "All Day the best local artists that capture the essence of urban life.",
    image: "https://ik.imagekit.io/mbhxghyf1m2/PA060073_a-Gb96FHE.jpg",
    artwork: "https://ik.imagekit.io/mbhxghyf1m2/PA060142_DY86mPz7Or.jpg",
  };

  // Define sponsors data
  const sponsors = [
    {
      name: "ArtCo",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20Square%20Interior%20Designer%20Logo_aTgwHH99Q.png",
    },
    {
      name: "PaintPals",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20and%20White%20Minimalist%20Professional%20Initial%20Logo%20(1)_47ilhSCRF.png",
    },
    {
      name: "CreativeBrush",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20Modern%20Handwritten%20Square%20Studio%20Logo%20(3)_FYGxaqQT8.png",
    },
    {
      name: "GalleryGurus",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Gray%20and%20Black%20Simple%20Studio%20Logo_MedfDB4bvN.png",
    },
  ];

  useEffect(() => {
    fetchArtLocations().then((data) => {
      // Filter out the unwanted locations here
      const filteredLocations = data.filter(location => 
        !["Pastel Dreams Gallery", "Retro Cinema Museum", "Timeless Art Space", "Culver City Arts District"]
        .includes(location.name)
      );
      setArtLocations(filteredLocations);
      setIsLoading(false);
    });
  }, []);

  const handleStartJourney = () => {
    navigate("/work-with-us");
  };

  return (
    <Box minHeight="100vh" overflow="hidden">
      <CursorSpotlight />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              {/* Hero Section with Video Background */}
              <HeroSection
                handleStartJourney={handleStartJourney}
                buttonSize={buttonSize}
              />
              {/* Enhanced Marquee animation */}
              <EnhancedMarquee />
              <FeaturedLocations
                artLocations={artLocations}
                isLoading={isLoading}
              />
              {/* Use the new UpcomingEvents component */}
              <UpcomingEvents />
              {/* Enhanced Live Music Section */}
              <ModernLiveMusicSection />
              {/* New Enhanced Stage Schedules Section */}
              <EnhancedStageSchedules />
              {/* Comment out VerticalVideo */}
              {/* <VerticalVideo /> */}
              <WorkWithUs />
              {/* Uncomment the SponsorSection */}
              <SponsorSection />
              {/* New PhotoGallery Section */}
              <Box bg={colorMode === "light" ? "white" : "gray.800"} py={16}>
                <Container maxW="container.xl">
                  <PhotoGallery />
                </Container>
              </Box>
            </>
          }
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/work-with-us" element={<WorkWithUs />} />
        <Route path="/entertainment" element={<EntertainmentPage />} />
        <Route path="/giveaway" element={<GiveawayPage />} />
      </Routes>

      {/* Footer */}
      <Box as="footer" bg="brand.600" color="white" py={8}>
        <Container maxW="container.xl">
          <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text textAlign={["center", "left"]} mb={[4, 0]}>
              &copy; 2024 Culver City Art Walk. All rights reserved.
            </Text>
            <Flex>
              <Tooltip label="Events">
                <IconButton
                  icon={<CalendarIcon />}
                  aria-label="Events"
                  variant="ghost"
                  mr={2}
                />
              </Tooltip>
              <Tooltip label="About">
                <IconButton
                  icon={<InfoIcon />}
                  aria-label="About"
                  variant="ghost"
                  mr={2}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default App;