import React from "react";
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Grid,
  Skeleton,
  useColorMode,
  Card,
  CardBody,
  Image,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import CountdownTimer from "./CountdownTimer";  // Import the CountdownTimer component

const FeaturedLocations = ({ artLocations, isLoading }) => {
  const { colorMode } = useColorMode();

  return (
    <Box bg={colorMode === "light" ? "gray.100" : "gray.800"} py={20}>
      <Container maxW="container.xl">
        <VStack spacing={12}>
          <Box textAlign="center" width="100%">
            <Heading
              as={motion.h2}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              size="2xl"
              mb={4}
              bgGradient="linear(to-r, brand.500, accent.500)"
              bgClip="text"
            >
              Saturday Oct 5th
            </Heading>
            
            <Box mb={8}>
              <CountdownTimer eventDate="2024-10-05T10:00:00" />
            </Box>

            {/* Festival Map */}
            <Box
              as={motion.div}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              mb={8}
            >
              <Heading as="h3" size="lg" mb={4}>
                Festival Map
              </Heading>
              <Box
                borderRadius="xl"
                overflow="hidden"
                boxShadow="xl"
                maxWidth="800px"
                margin="0 auto"
              >
                <Image
                  src="https://assetsjcoholan.s3.us-west-1.amazonaws.com/art_walk_site/InternalM.jpg"
                  alt="Culver City Arts District Festival Map"
                  width="100%"
                  height="auto"
                />
              </Box>
            </Box>

            <Text
              as={motion.p}
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              fontSize="xl"
              maxW="2xl"
              mx="auto"
            >
              We're turning up the volume! Culver City Arts District
              and Arts District Residents Association are proud to
              present this year's rockin' Art Walk and Roll Festival.
            </Text>
          </Box>
          <Grid
            templateColumns={[
              "1fr",
              "1fr",
              "repeat(2, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={8}
            as={motion.div}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <AnimatePresence>
              {isLoading
                ? Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <Skeleton
                        key={index}
                        height="200px"
                        borderRadius="xl"
                      />
                    ))
                : artLocations.map((location) => (
                    <Card key={location.id}>
                      <CardBody>
                        <Heading size="md" mb={2}>{location.name}</Heading>
                        <Text>{location.address}</Text>
                      </CardBody>
                    </Card>
                  ))}
            </AnimatePresence>
          </Grid>
        </VStack>
      </Container>
    </Box>
  );
};

export default FeaturedLocations;